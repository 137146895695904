<template>
  <v-autocomplete
    v-model="iProductId"
    :hide-details="!sHint ? hideDetails : false"
    :hint="sHint"
    :items="items"
    :label="hideLabel ? undefined : $t('product')"
    :loading="loading"
    :menu-props="{ bottom: true }"
    :persistent-hint="!!sHint"
    clearable
    dense
    :item-text="itemText"
    item-value="id"
    no-filter
    outlined
    v-bind="$attrs"
    @change="onChange"
    @update:search-input="onSearch"
    @click:clear="preLoad"
  >
    <template #item="{ item }">
      <product-preview :product="item" />
    </template>
  </v-autocomplete>
</template>

<script lang="ts">
import { Product, ProductCollection } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import ProductPreview from "@/modules/products/components/ProductPreview.vue";
import type { DebounceFunction } from "@/plugins/helpers";
import {
  debounce,
  differenceWith,
  filter,
  get,
  isEqual,
  isNil,
  set,
  toPairs,
  truncate,
} from "lodash";

@Component({
  components: { ProductPreview },
})
export default class ProductSelect extends Vue {
  @VModel({ type: Number }) iProductId!: number;
  @Prop(Number) priceListId!: number;
  @Prop(Number) taxtypeId!: number;
  @Prop(Boolean) withPrice!: boolean;
  @Prop(Boolean) sell!: boolean;
  @Prop(Boolean) service!: boolean;
  @Prop(Boolean) withoutGenerated!: boolean;
  @Prop(Boolean) hideLabel!: boolean;
  @Prop(Boolean) itemTextCode!: boolean;
  @Prop(String) hint!: string | undefined;
  @Prop({ type: [Boolean, String], default: "auto" }) readonly hideDetails!:
    | boolean
    | "auto";

  obCollection: ProductCollection = new ProductCollection();
  obSelected: Product | null | undefined = null;
  fnDebounceSearch!: DebounceFunction;
  sSearch = "";

  get loading() {
    return this.obCollection.loading;
  }

  get items() {
    return this.obCollection.getModelList();
  }

  get itemText(): string {
    return this.itemTextCode ? "code" : "name";
  }

  get selected() {
    return this.iProductId && this.obCollection.length
      ? this.obCollection.find({ id: this.iProductId })
      : undefined;
  }

  get sHint() {
    return this.hint
      ? this.hint
      : this.selected
      ? truncate(this.selected.preview_text)
      : null;
  }

  get obFilters(): Record<string, any> {
    const obFilterData = {}; // TODO remove for prod eze  { active: 1 }
    const sCollectionSearch = get(
      this.obCollection.get("filters", {}),
      "search"
    );

    // Search by words
    if (this.sSearch && !isEqual(this.sSearch, sCollectionSearch)) {
      set(obFilterData, "search", this.sSearch);
    }

    // Filter by price list
    if (this.priceListId) {
      set(obFilterData, "offerByPriceList", this.priceListId);
    }

    // Filter only with price > 0
    if (this.withPrice) {
      set(obFilterData, "withPrice", 1);
    }

    // Filter only by sell products
    if (this.sell) {
      set(obFilterData, "bySell", 1);
    }

    // Filter only by service products
    if (this.service) {
      set(obFilterData, "byService", 1);
    }

    // Filter auto generated products
    if (this.withoutGenerated) {
      set(obFilterData, "byNotGenerated", 1);
    }

    if (this.taxtypeId) {
      set(obFilterData, "byTaxType", this.taxtypeId);
    }

    return obFilterData;
  }

  @Watch("obFilters")
  onCangeFilters(
    obNewData: Record<string, any>,
    obOldData?: Record<string, any>
  ) {
    let changes = differenceWith(
      toPairs(obNewData),
      toPairs(obOldData),
      isEqual
    );

    changes = filter(changes, (arItem: string[]) => {
      return arItem.length >= 2 && arItem[0] !== "search";
    }) as [string, any][];

    if (!changes.length) {
      return;
    }

    this.preLoad();
  }

  @Watch("iProductId")
  async onChangeValue() {
    await this.load();
  }

  /*@Watch("priceListId")
  onChangePriceList(iPriceList?: number) {
    if (
      !iPriceList ||
      (this.obSelected &&
        this.obSelected.get("offers.0.price_list_id") === iPriceList)
    ) {
      return;
    }

    console.log("Preload from price list");
    this.preLoad();
  }

  @Watch("sell")
  onChangeSell() {
    console.log("Preload from sell");
    this.preLoad();
  }

  @Watch("service")
  onChangeService() {
    console.log("Preload from service");
    this.preLoad();
  }*/

  // @Watch("sSearch")
  onSearch(sValue: string) {
    this.sSearch = sValue;
    this.fnDebounceSearch();
  }

  async mounted() {
    await this.preLoad();
  }

  created() {
    this.fnDebounceSearch = debounce(this.search, 500);
  }

  async load() {
    if (
      !this.iProductId ||
      (this.obSelected && this.obSelected.id === this.iProductId)
    ) {
      return;
    }

    if (this.obCollection.length) {
      this.obSelected = this.obCollection.find({ id: this.iProductId });
      if (this.obSelected) {
        this.onChange();
        return;
      }
    }

    this.obSelected = new Product({ id: this.iProductId }, this.obCollection);
    await this.obSelected.fetch();
    this.obCollection.add(this.obSelected);
    this.onChange();
  }

  async preLoad() {
    this.sSearch = "";
    // this.$emit("input", undefined);

    this.obCollection = new ProductCollection();
    this.obCollection.filterBy(this.obFilters);
    await this.obCollection.limit(10).fetch();
    await this.load();
  }

  async search() {
    if (this.loading) {
      return;
    }

    if (!isNil(this.sSearch) && this.sSearch.length) {
      const obFilters = this.obCollection.get("filters", {});
      if (get(obFilters, "search") == this.sSearch) {
        return;
      }

      if (this.obCollection.find({ name: this.sSearch })) {
        return;
      }
    } else {
      this.preLoad();

      return;
    }

    this.obCollection.filterBy(this.obFilters);
    this.obCollection.clear();
    this.obCollection.page(1);

    await this.obCollection.fetch();
  }

  onChange(iProduct?: number) {
    if (
      iProduct &&
      (iProduct !== this.iProductId ||
        (this.obSelected && this.obSelected.id !== iProduct))
    ) {
      this.obSelected = this.obCollection.find({ id: iProduct });
    }

    this.$emit("change", this.obSelected);
  }
}
</script>
